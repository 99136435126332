<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span class="SubHead">报表导出记录</span>
        <router-link to="/reportList"><span class="brandColor" style="font-size:14px;">返回</span></router-link>
      </div>
    </template>
    <el-form ref="reportFormRef" :rules="reportFormRules" :inline="true" :model="reportForm" style="padding-top: 15px;">
      <el-form-item label="报表名称" prop="name">
        <el-input placeholder="请输入报表名称" size="small" clearable v-model="reportForm.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="queryFormBtn">查询</el-button>
        <el-button @click="resetFormBtn" size="small">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="reportRecordRef"
      :data="reportRecordList"
      :row-key="getListId"
      style="width: 100%"
      :header-cell-style="{
        background: 'var(--el-table-row-hover-background-color)'
      }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column property="name" label="报表名称" />
      <el-table-column property="" label="媒体">
        <template #default="{row}">
          <span v-if="row.media_type == 1">Facebook</span>
          <span v-else>TikTok</span>
        </template>
      </el-table-column>
      <el-table-column property="create_at" label="创建时间" />
      <el-table-column property="update_at" label="最近导出时间" />
      <el-table-column label="操作">
        <template #default="{row}">
          <el-button type="text" size="mini" @click="downRecord(row.id, row.name)">下载</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <img src="@/assets/empty.gif" alt="" style="width:400px;height:400px" />
      </template>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[2, 5, 10]"
      :page-size="queryInfo.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background
    >
    </el-pagination>
  </el-card>
</template>

<script>
import { ElLoading } from 'element-plus'
export default {
  name: 'ReportRecord',
  data() {
    return {
      userID: '',
      userInfo: '',
      SelectNumber: 0,
      // 分页切换查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      total: 0,
      reportForm: {
        name: ''
      },
      reportFormRules: {
        // name: [{ required: true, message: '请输入报告名称', trigger: 'blur' }]
      },
      reportRecordList: []
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getReportRecordList()
  },
  methods: {
    async getReportRecordList() {
      this.userID = window.localStorage.getItem('FB_userID')
      const { data: res } = await this.$http.post('cloudfb/export_list', {
        name: this.reportForm.name,
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        user_id: this.userID,
      })
      if (res.code == 100) {
        return this.$message.error(`${res.msg}`)
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.reportRecordList = res.data
      this.total = res.total
    },
    // table需设置row-key
    getListId(account) {
      // return account.advertiser_id
    },
    handleSelectionChange(selection) {
      this.SelectNumber = selection.length
    },
    // 分页展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getReportRecordList()
    },
    // 分页展示点击分页触发的函数
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getReportRecordList()
    },
    downRecord(id, name) {
      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...'
      })

      this.$http({
        // 用axios发送post请求
        method: 'post',
        url: 'cloudfb/list_export', // 请求地址
        data: {
          token: this.userInfo.token,
          id: id
        },
        responseType: 'blob' // 表明返回服务器返回的数据类型
      })
        .then(res => {
          if (res.code == 100) {
            return this.$message.warning(`${res.msg}`)
          }
          // 处理返回的文件流
          const content = res.data
          const blob = new Blob([content])
          const fileName = `${name}.xls`
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          loading.close()
        })
      this.downLoadDialogVisible = false
    },
    queryFormBtn() {
      this.$refs.reportFormRef.validate(valid => {
        if (!valid) return
        this.getReportRecordList()
      })
    },
    resetFormBtn(){
      this.$refs.reportFormRef.resetFields()
      this.getReportRecordList()
    }
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
}
</style>
