<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span class="SubHead">Facebook广告账户数据报表</span>
        <el-button class="subNotice" type="text" @click="fbAccredit">
          <span class="brandColor">Facebook授权</span>
        </el-button>
      </div>
    </template>
    <el-alert title="帮助提示" description="" type="info" show-icon>
      <template #default>
        为了更方便的展示您的广告数据，请您对Facebook账号进行授权。<br />
        <el-button type="text" class="FBBtn" @click="fbAccredit"><span class="brandColor">前往授权</span></el-button>
      </template>
    </el-alert>
    <el-form ref="FBReportFormRef" :rules="FBReportFormRules" :inline="true" :model="FBReportForm"
      style="padding-top: 15px;">
      <el-row style="position:relative;">
        <el-form-item label="广告账户ID" prop="id">
          <el-input placeholder="请输入广告账户ID" size="small" clearable v-model="FBReportForm.id"
            style="width:190px;"></el-input>
        </el-form-item>
        <el-form-item label="账户状态" prop="status">
          <el-select v-model="FBReportForm.status" clearable placeholder="请选择状态" size="small" style="width:150px;">
            <el-option label="正常" value="1"></el-option>
            <el-option label="被封" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择日期" prop="timeRange">
          <el-date-picker v-model="FBReportForm.timeRange" type="daterange" size="small" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="X" :disabled-date="disabledDate" @calendar-change="calendarChange"
            :clearable="false" style="width:240px;"></el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: 9px;">
          <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
          <el-button @click="resetFormBtn" size="small">重置</el-button>
        </el-form-item>
        <el-form-item style="position:absolute;right:0;">
          <el-button type="text" @click="reportDownload">
            <span class="brandColor">下载报表</span>
          </el-button>
          <!-- <el-tooltip class="item" effect="light" placement="top" style="max-width: 300px;">
            <template #content>
              自定义列设置
            </template>
            <el-button type="text" @click="configField">
              <i class="iconfont icon-shezhi configField"></i>
            </el-button>
          </el-tooltip> -->
        </el-form-item>
      </el-row>
    </el-form>
    <el-table ref="allAccountRef" :data="allFieldList" :row-key="getListId" style="width: 100%" :header-cell-style="{
      background: 'var(--el-table-row-hover-background-color)'
    }" @sort-change="sortChange" @selection-change="handleSelectionChange">
      <el-table-column type="selection" :reserve-selection="true" fixed width="55" />
      <el-table-column property="account_id" label="广告账户ID" fixed width="150" />
      <el-table-column label="账户状态">
        <template #default="{ row }">
          <el-tag type="success" v-if="row.account_status == 1">正常</el-tag>
          <el-tag type="danger" v-else>被封</el-tag>
        </template>
      </el-table-column>
      <el-table-column property="sum_spent" label="消耗" sortable="custom">
        <template #default="{ row }"> {{ $filters.thousands(row.sum_spent) }} </template>
      </el-table-column>
      <el-table-column property="sum_impressions" label="展示次数" sortable="custom" min-width="100">
        <template #default="{ row }"> {{ $filters.thousands(row.sum_impressions) }} </template>
      </el-table-column>
      <el-table-column property="sum_cpc" label="CPC" sortable="custom">
        <template #default="{ row }"> ${{ $filters.thousands(row.sum_cpc) }} </template>
      </el-table-column>
      <el-table-column property="sum_cpm" label="CPM" sortable="custom">
        <template #default="{ row }"> ${{ $filters.thousands(row.sum_cpm) }} </template>
      </el-table-column>
      <el-table-column property="sum_ctr" label="CTR" sortable="custom">
        <template #default="{ row }"> {{ row.sum_ctr }}% </template>
      </el-table-column>
      <el-table-column property="sum_add" label="ATC" sortable="custom">
        <template #default="{ row }"> ${{ $filters.thousands(row.sum_add) }} </template>
      </el-table-column>
      <el-table-column property="cpa" label="CPA" sortable="custom">
        <template #default="{ row }"> ${{ $filters.thousands(row.cpa) }} </template>
      </el-table-column>
      <el-table-column property="roas" label="ROAS" sortable />
      <template #empty>
        <img src="@/assets/empty.gif" alt="" style="width:400px;height:400px" />
      </template>
    </el-table>
    <!-- 分页器 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
      :page-sizes="[2, 5, 10]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
      :total="total" background>
    </el-pagination>
  </el-card>

  <!-- 下载数据报表弹窗 -->
  <el-dialog v-model="downLoadDialogVisible" title="下载报表" width="790px">
    <!-- 内容主体区域 -->
    <el-form ref="downLoadFormRef" :rules="downLoadFormRules" :model="downLoadForm" label-width="100px">
      <el-form-item label="报表名称" prop="listName">
        <el-input size="small" v-model="downLoadForm.listName" class="listName" clearable></el-input>
      </el-form-item>
      <el-form-item label="选择时间" class="timeRange" prop="reportTimeRange">
        <el-date-picker v-model="downLoadForm.reportTimeRange" type="daterange" size="small" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="X" :disabled-date="disabledDate1"
          @calendar-change="calendarChange1"></el-date-picker>
      </el-form-item>
      <el-form-item label="选择字段" class="downLoadForm">
        <el-transfer v-model="reportValue" style="text-align: left; display: inline-block" :left-default-checked="[]"
          :right-default-checked="[]" :titles="['可选字段', '已选字段']" :button-texts="['移除', '添加']" :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}'
          }" :data="reportData" :clearable="false">
        </el-transfer>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="downLoadFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="downLoadFormBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- FB授权弹框 -->
  <el-dialog custom-class="FBAccredit" v-model="accreditDialogVisible" title="Facebook授权" width="800px">
    <el-alert title="注：只能管理在MMCloud申请的广告账户，授权仅本次登陆有效" show-icon :closable="false" type="info" />
    <div class="desTitle">
      <span>已授权用户</span>
      <!-- <el-button type="text" class="circleBtn" circle size="small" @click="fbLogin">
        <i class="el-icon-circle-plus"></i>
      </el-button> -->
      <el-button type="text" @click="fbLogin" size="small">
        <span class="brandColor" style="font-size:14px;">新增Facebook用户授权</span>
      </el-button>
    </div>
    <el-descriptions title="" label-align="left" :column="4" v-show="FBUserInfo.length > 0">
      <template #title></template>
      <template v-for="item in FBUserInfo" :key="item.id">
        <el-descriptions-item label="">
          <el-avatar :size="30" :src="item.url" style="vertical-align: middle;" />
          {{ item.name }}
        </el-descriptions-item>
        <el-descriptions-item label="id：">{{ item.id }}</el-descriptions-item>
        <el-descriptions-item label="广告账户个数：">{{ item.num }}个</el-descriptions-item>
        <el-descriptions-item label="">
          <div style="display: inline-block;width:100%;text-align:right;">
            <span style="color:#00f1a2;font-size:14px;margin-right: 8px;" v-if="userID == item.id">已授权当前用户</span>
            <el-button type="text" style="margin-right: 8px;" @click="fbLogin" v-else>
              <span class="brandColor">切换当前用户授权</span>
            </el-button>
          </div>
        </el-descriptions-item>
      </template>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import { ElLoading } from 'element-plus'
import Sortable from 'sortablejs'
export default {
  name: 'FBReport',
  data() {
    window.fbAsyncInit = function () {
      FB.init({
        appId: '826283441046676',
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: 'v14.0' // Specify the Graph API version to use
      })
    }
      // Load the SDK asynchronously
      ; (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')

    return {
      accreditDialogVisible: false,
      FBResToken: '',
      FBResID: '',
      FBName: '',
      FBUrl: '',
      userID: '',
      userInfo: '',
      FBUserInfo: [],
      FBReportForm: {
        timeRange: '',
        id: '',
        status: ''
      },
      FBReportFormRules: {
        timeRange: [{ required: true, message: '请选择时间范围', trigger: 'blur' }]
      },
      startDate: null,
      startDate1: null,
      // 列表字段
      allFieldList: [],
      SelectNumber: 0,
      // 分页切换查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      total: 0,
      downLoadDialogVisible: false,
      downLoadForm: {
        listName: '',
        reportTimeRange: ''
      },
      //   穿梭框右侧的字段
      reportValue: ['account_id', 'account_status', 'spent'],
      //   穿梭框左侧侧的字段
      reportData: [
        {
          key: 'account_id',
          label: '广告账户ID',
          disabled: true
        },
        {
          key: 'account_status',
          label: '账户状态',
          disabled: true
        },
        {
          key: 'spent',
          label: '消耗',
          disabled: true
        },
        {
          key: 'impressions',
          label: '展示次数'
        },
        {
          key: 'cpc',
          label: 'CPC'
        },
        {
          key: 'cpm',
          label: 'CPM'
        },
        {
          key: 'ctr',
          label: 'CTR'
        },
        {
          key: 'atc',
          label: 'ATC'
        },
        {
          key: 'cpa',
          label: 'CPA'
        },
        {
          key: 'roas',
          label: 'ROAS'
        }
      ],
      downLoadFormRules: {
        listName: [{ required: true, message: '请输入报表名称', trigger: 'blur' }],
        reportTimeRange: [{ required: true, message: '请选择时间范围', trigger: 'change' }]
      }
    }
  },
  created() {
    if (this.FBReportForm.timeRange == '') {
      this.FBReportForm.timeRange = this.getSevenDays()
    }
    if (this.downLoadForm.reportTimeRange == '') {
      this.downLoadForm.reportTimeRange = this.getSevenDays()
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getAllFieldList()
  },
  methods: {
    fbAccredit() {
      this.getFBUserInfo()
      this.accreditDialogVisible = true
    },

    fbLogin() {
      var that = this
      FB.login(
        function (response) {
          if (response.status === 'connected') {
            FB.api('/me?fields=id,name,picture', function (res) {
              that.FBName = res.name
              that.FBUrl = res.picture.data.url
              that.FBResToken = response.authResponse.accessToken
              that.FBResID = response.authResponse.userID
              if (response) {
                that.postFBRes()
              }
            })
          } else {
            // The person is not logged into your webpage or we are unable to tell.
          }
        },
        { scope: 'public_profile,email,ads_management,business_management' }
      )
    },
    async postFBRes() {
      const { data: res } = await this.$http.post('cloudfb/fb_authorize', {
        token: this.FBResToken,
        user_id: this.FBResID,
        name: this.FBName,
        url: this.FBUrl
      })
      if (res.code == 100) {
        return this.$message.error(`${res.msg}`)
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 200) {
        window.localStorage.setItem('FB_userID', this.FBResID)
        this.getAllFieldList()
        this.getFBUserInfo()
      }
    },
    // 获取已授权账户的信息
    async getFBUserInfo() {
      const { data: res } = await this.$http.post('cloudfb/authorize_list')

      if (res.code == 100) {
        return this.$message.error(`${res.msg}`)
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.FBUserInfo = res.data
    },
    // 获取列表的数据
    async getAllFieldList() {
      this.userID = window.localStorage.getItem('FB_userID')
      // this.userID = 740312059914663
      const { data: res } = await this.$http.post('cloudfb/account_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        id: this.FBReportForm.id,
        status: this.FBReportForm.status,
        time: this.FBReportForm.timeRange,
        user_id: this.userID
        // user_id: 740312059914663
      })
      if (res.code == 100) {
        return this.$message.error(`${res.msg}`)
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.allFieldList = res.data
      this.total = res.total
    },
    // 默认显示近7天时间范围
    getSevenDays() {
      const start = Math.round((new Date().getTime() - 8.64e7 * 7) / 1000)
      const end = Math.round((new Date().getTime() - 8.64e7) / 1000)
      return [start, end]
    },
    reportDownload() {
      if (this.allFieldList.length == 0) {
        return this.$message.warning('暂无数据')
      }
      this.downLoadDialogVisible = true
    },
    configField() {
      // console.log('配置')
    },
    // table需设置row-key
    getListId(account) {
      //   return account.advertiser_id
    },
    handleSelectionChange(selection) {
      this.SelectNumber = selection.length
    },
    // 分页展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getAllFieldList()
    },
    // 分页展示点击分页触发的函数
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getAllFieldList()
    },
    downLoadFormClose() {
      this.downLoadDialogVisible = false
    },
    downLoadFormBtn() {
      this.$refs.downLoadFormRef.validate(vaild => {
        if (!vaild) return
        var reportValue = this.reportValue.join(',')
        const loading = ElLoading.service({
          spinner: 'el-icon-loading',
          lock: true,
          text: '下载中，请稍后...'
        })

        this.$http({
          // 用axios发送post请求
          method: 'post',
          url: 'cloudfb/account_export', // 请求地址
          data: {
            token: this.userInfo.token,
            time: this.downLoadForm.reportTimeRange,
            field: reportValue,
            user_id: this.userID,
            // user_id: 740312059914663,
            name: this.downLoadForm.listName
          },
          responseType: 'blob' // 表明返回服务器返回的数据类型
        })
          .then(res => {
            console.log(res)
            if (res.code == 100) {
              return this.$message.warning(`${res.msg}`)
            }
            // 处理返回的文件流
            const content = res.data
            const blob = new Blob([content])
            const fileName = `${this.downLoadForm.listName}.xls`
            if ('download' in document.createElement('a')) {
              // 非IE下载
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
          })
          .finally(() => {
            loading.close()
          })
        this.downLoadDialogVisible = false
      })
    },
    queryFormBtn() {
      this.$refs.FBReportFormRef.validate(valid => {
        if (!valid) return
        this.getAllFieldList()
      })
    },
    resetFormBtn() {
      this.$refs.FBReportFormRef.resetFields()
      // this.getSevenDays()
      this.startDate = null
      this.getAllFieldList()
    },
    // 日期选择器禁止选择的条件
    disabledDate(time) {
      // 8.64e7 等于 3600 * 1000 * 24
      if (this.startDate !== null) {
        return (
          time.getTime() > Date.now() - 8.64e7 ||
          time.getTime() < 1640966400 * 1000 ||
          time.getTime() > this.startDate.getTime() + 30 * 8.64e7 ||
          time.getTime() < this.startDate.getTime() - 30 * 8.64e7
        )
      } else {
        return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 8.64e7
      }
    },
    calendarChange(dates) {
      // 记录选择的开始日期，方便后面根据开始日期限定结束日期
      if (dates !== null && dates.length > 0) {
        this.startDate = dates[0]
      }
    },
    // 下载报表区域的时间选择器
    disabledDate1(time) {
      // 8.64e7 等于 3600 * 1000 * 24
      if (this.startDate1 !== null) {
        return (
          time.getTime() > Date.now() - 8.64e7 ||
          time.getTime() < 1640966400 * 1000 ||
          time.getTime() > this.startDate1.getTime() + 30 * 8.64e7 ||
          time.getTime() < this.startDate1.getTime() - 30 * 8.64e7
        )
      } else {
        return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 8.64e7
      }
    },
    calendarChange1(dates) {
      // 记录选择的开始日期，方便后面根据开始日期限定结束日期
      if (dates !== null && dates.length > 0) {
        this.startDate1 = dates[0]
      }
    },
    async sortChange({ prop, order }) {
      const { data: res } = await this.$http.post('cloudfb/account_sort', {
        type: prop,
        sort: order,
        user_id: this.userID,
        time: this.FBReportForm.timeRange
      })
      if (res.code == 200) {
        this.getAllFieldList()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-alert--warning.is-light,
.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
}

:deep(.el-alert__icon) {
  background-color: #e6f7ff !important;
  color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}

:deep(.el-alert .el-alert__description) {
  font-size: 14px;
  color: #848d92 !important;
}

.fillGuide {
  color: #2b82ff;
  vertical-align: baseline !important;
  margin-left: 5px;
}

.FBBtn {
  min-height: auto;
  padding: 5px 0;
}

.configField {
  font-size: 18px;
  color: #2b82ff;
  margin-left: 8px;
}

.listName {
  width: 220px !important;
}

.timeRange {
  :deep(.el-date-editor.el-input__inner) {
    width: 220px !important;
  }
}

.downLoadForm {
  .el-checkbox {
    width: 100px;
  }

  :deep(.el-transfer__button) {
    display: block;
    min-height: 32px;
    padding: 9px 15px;
    font-size: 12px;
  }

  :deep(.el-transfer__button:nth-child(2)) {
    margin-left: 0 !important;
  }
}

:deep(.el-descriptions__header) {
  margin: 6px 0;
}

:deep(.el-descriptions__title) {
  width: 100%;
  font-size: 14px;
  color: #595959;
  font-weight: 400;
}

.AuthTips {
  margin-left: 10px;
}

.desTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.circleBtn {
  padding: 0;

  .el-icon-circle-plus {
    font-size: 30px;
    color: #2b82ff;
  }
}
</style>
